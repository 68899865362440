import shopifyDawnGenericDefaults from '../shopify-dawn-generic/config.js';

export default {
  includes: ['shopify-dawn-generic'],
  ...shopifyDawnGenericDefaults,
  fitmentSearch: {
    ...shopifyDawnGenericDefaults.fitmentSearch,
    onVerifyFitmentInitResponseReceived: (response) => {
      const noFitmentData = response.Items?.[0]?.fitsTheVehicle === 'unknown';
      if (noFitmentData) {
        const verifyFitmentWidget = window.document.querySelector('#cm-verify-fitment');
        if (verifyFitmentWidget) {
          verifyFitmentWidget.style.display = 'none';
        }
      }
    },
  },
};
