//convermax workaround for lack of css module import info experiments.css
var css = "\nhead{--webpack-convermax-client-ui-8:_9812,_3009,_8816,_860,_7140,_9616,_7852,_4556;}"
var style = document.createElement('style');
style.appendChild(document.createTextNode(css));
document.head.appendChild(style);
try{
        if(!getComputedStyle(document.head).getPropertyValue('--webpack-convermax-client-ui-8')) {
          document.head.style.setProperty('--webpack-convermax-client-ui-8', "_9812,_3009,_8816,_860,_7140,_9616,_7852,_4556");
        }
      }catch (ex) {
        console.error(ex);
      }